import React from 'react';
import { graphql } from 'gatsby';
import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import loadable from '@loadable/component';

import Layout from 'components/shared/Layout';
import SEO from 'components/shared/Seo';

let MainpageContent = null;

gsap.registerPlugin(CSSPlugin);

const PageTemplate = ({ data }) => {
  const {
    allWordpressPage,
    allWordpressWpAppWork,
    allWordpressWpAppTestimonial,
    allWordpressWpAppWorkTag,
  } = data;

  const tags = allWordpressWpAppWorkTag.edges;

  const currentPageData = allWordpressPage.edges[0].node;
  const worksPageData = allWordpressWpAppWork.nodes;
  const testimonialsPageData = allWordpressWpAppTestimonial.nodes;
  const currentPageWidgetsData = currentPageData.acf.widgets_page || [];
  const invertedColors = currentPageData.acf.inverted_colors;
  const breadcrumbsData = {
    data: {
      title: currentPageData.title,
      path: currentPageData.path,
    },
    parent: currentPageData.parent_element,
  };
  const pageLink = currentPageData.link;

  let footerType = '';
  let footerFullInverted = '';
  let pageComponents = [];

  currentPageWidgetsData.forEach((widget, id) => {
    switch (widget.__typename) {
      case 'WordPressAcf_banner_full_screen':
        const BannerFullScreen = loadable(() =>
          import('../components/organisms/BannerFullScreen')
        );
        pageComponents.push(<BannerFullScreen data={widget} key={id} />);
        break;

      case 'WordPressAcf_contact':
        const BannerContact = loadable(() =>
          import('../components/organisms/BannerContact')
        );
        pageComponents.push(
          <BannerContact className="banner-contact" data={widget} key={id} />
        );
        break;

      case 'WordPressAcf_works':
        const WorksList = loadable(() =>
          import('../components/organisms/WorksList')
        );
        pageComponents.push(
          <WorksList
            data={worksPageData}
            title={widget.caption.title}
            key={id}
            breadcrumbsData={breadcrumbsData}
            pageLink={pageLink}
          />
        );
        break;

      case 'WordPressAcf_clients':
        const SelectedClients = loadable(() =>
          import('../components/organisms/SelectedClients')
        );
        pageComponents.push(
          <SelectedClients
            className="selected-clients"
            data={widget}
            key={id}
          />
        );
        break;

      case 'WordPressAcf_clients_grid':
        const ClientsGrid = loadable(() =>
          import('../components/organisms/ClientsGrid')
        );
        pageComponents.push(
          <ClientsGrid
            className="new-selected-clients"
            data={widget}
            key={id}
            pageLink={pageLink}
          />
        );
        break;

      case 'WordPressAcf_key_metrics':
        // pageComponents.push(<ScrollSlider data={widget} key={id} />);
        break;

      case 'WordPressAcf_heading':
        const Heading = loadable(() =>
          import('../components/organisms/Heading')
        );
        pageComponents.push(<Heading data={widget} key={id} />);
        break;

      case 'WordPressAcf_works_table':
        const ProjectsAccordion = loadable(() =>
          import('../components/organisms/ProjectsAccordion')
        );
        pageComponents.push(
          <ProjectsAccordion data={widget} tagsData={tags} key={id} />
        );
        break;

      case 'WordPressAcf_cta':
        const CallToAction = loadable(() =>
          import('../components/organisms/CallToAction')
        );
        pageComponents.push(<CallToAction data={widget} key={id} />);
        break;

      case 'WordPressAcf_banner':
        const BannerWithImage = loadable(() =>
          import('../components/organisms/BannerWithImage')
        );
        pageComponents.push(
          <BannerWithImage
            data={widget}
            // clutch={showClutch}
            key={id}
            breadcrumbsData={breadcrumbsData}
          />
        );
        break;

      case 'WordPressAcf_works_featured':
        const WorksFeatured = loadable(() =>
          import('../components/organisms/WorksFeatured')
        );
        pageComponents.push(
          <WorksFeatured
            data={widget}
            worksData={worksPageData}
            tagsData={tags}
            key={id}
          />
        );
        break;

      case 'WordPressAcf_text_media':
        const TextMedia = loadable(() =>
          import('../components/organisms/TextMedia')
        );
        pageComponents.push(<TextMedia data={widget} key={id} />);
        break;

      case 'WordPressAcf_team':
        const Team = loadable(() => import('../components/organisms/Team'));
        pageComponents.push(
          <Team data={widget} pageName={currentPageData.slug} key={id} />
        );
        break;

      case 'WordPressAcf_testimonials':
        const Testimonials = loadable(() =>
          import('../components/organisms/Testimonials')
        );
        pageComponents.push(
          <Testimonials
            data={widget}
            testimonialsData={testimonialsPageData}
            key={id}
          />
        );
        break;

      case 'WordPressAcf_workflow':
        const Workflow = loadable(() =>
          import('../components/organisms/Workflow')
        );
        pageComponents.push(<Workflow data={widget} key={id} />);
        break;

      case 'WordPressAcf_logos':
        const LogosBlock = loadable(() =>
          import('../components/organisms/LogosBlock')
        );
        pageComponents.push(<LogosBlock data={widget} key={id} />);
        break;

      case 'WordPressAcf_bullet_lists':
        const BulletLists = loadable(() =>
          import('../components/organisms/BulletLists')
        );
        pageComponents.push(<BulletLists data={widget} key={id} />);
        break;

      case 'WordPressAcf_industries':
        const Industries = loadable(() =>
          import('../components/organisms/Industries')
        );
        pageComponents.push(
          <Industries
            data={widget}
            breadcrumbsData={breadcrumbsData}
            key={id}
          />
        );
        break;

      case 'WordPressAcf_footer_full':
        footerType = 'full';
        footerFullInverted = widget.color_scheme === 'dark' ? true : false;
        break;

      case 'WordPressAcf_footer_compact':
        footerType = 'compact';
        break;

      case 'WordPressAcf_awards':
        const MainpageLogotypes = loadable(() =>
          import('../components/organisms/MainpageLogotypes')
        );
        pageComponents.push(<MainpageLogotypes data={widget} key={id} />);
        break;

      case 'WordPressAcf_case_studies_featured':
        MainpageContent =
          MainpageContent ||
          loadable(() => import('../components/organisms/MainpageContent'));
        pageComponents.push(<MainpageContent data={widget} key={id} />);
        break;

      case 'WordPressAcf_services_featured':
        MainpageContent =
          MainpageContent ||
          loadable(() => import('../components/organisms/MainpageContent'));
        pageComponents.push(
          <MainpageContent data={widget} key={id} tagsData={tags} />
        );
        break;

      case 'WordPressAcf_posts_featured':
        MainpageContent =
          MainpageContent ||
          loadable(() => import('../components/organisms/MainpageContent'));
        pageComponents.push(<MainpageContent data={widget} blog key={id} />);
        break;

      case 'WordPressAcf_industries_featured':
        MainpageContent =
          MainpageContent ||
          loadable(() => import('../components/organisms/MainpageContent'));
        pageComponents.push(<MainpageContent data={widget} key={id} />);
        break;

      case 'WordPressAcf_expandable_sections':
        const ExpandableSections = loadable(() =>
          import('../components/organisms/ExpandableSections')
        );
        pageComponents.push(<ExpandableSections data={widget} key={id} />);
        break;

      case 'WordPressAcf_bullet_list':
        const BulletListIndustry = loadable(() =>
          import('../components/organisms/BulletListIndustry')
        );
        pageComponents.push(
          <BulletListIndustry data={widget} key={id} services wide />
        );
        break;

      case 'WordPressAcf_text_icons':
        const TextIconsIndustry = loadable(() =>
          import('../components/organisms/TextIconsIndustry')
        );
        pageComponents.push(
          <TextIconsIndustry data={widget} key={id} services />
        );
        break;

      case 'WordPressAcf_html_section':
        const HtmlSection = loadable(() =>
          import('../components/organisms/HtmlSection')
        );
        pageComponents.push(<HtmlSection data={widget} key={id} />);
        break;

      default:
        break;
    }
  });

  return (
    <>
      <Layout
        className={currentPageData.slug}
        isInverted={invertedColors}
        pageWidgetsData={currentPageWidgetsData}
        footerType={footerType}
        footerFullInverted={footerFullInverted}
      >
        <SEO yoastMeta={currentPageData.yoast_meta} url={pageLink} />
        {pageComponents}
      </Layout>
    </>
  );
};

export default PageTemplate;

export const query = graphql`
  query($id: String!) {
    allWordpressPage(filter: { id: { eq: $id } }) {
      ...AllWordpressPageFragment
    }
    allWordpressWpAppWork(
      sort: { fields: menu_order, order: ASC }
      filter: { slug: { ne: "placeholder" } }
    ) {
      ...AllWordpressWpAppWorkFragment
    }
    allWordpressWpAppTestimonial {
      ...AllWordpressWpAppTestimonialFragment
    }
    allWordpressWpAppWorkTag {
      ...AllWordpressWpAppWorkTagFragment
    }
  }
`;
